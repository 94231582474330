<template>
  <v-container>
    <v-row>
      <v-col>
        <InlineForm
          v-if="workcenter"
          :entity="workcenter"
          :entityStore="workcentersStore"
          :canEdit="appStore.canEditCurrentFacility"
          :fields="['title', 'active', 'facility_id']"
        >
          <template v-slot:default="{ data, childProps, childEvents }">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <InlineEdit
                    v-bind="childProps.title"
                    v-on="childEvents.title"
                    label="Name"
                    :rules="[rules.required, uniqueTitle]"
                    required
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <InlineEdit
                    :value="facilitiesStore.getFacilityCode(data.facility_id)"
                    label="Facility"
                    :disabled="true"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <InlineEdit
                    v-bind="childProps.active"
                    v-on="childEvents.active"
                    label="Active"
                    inputType="checkbox"
                    hide-details
                    class="shrink mr-2"
                    :canEdit="!hasActiveProcesses"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </InlineForm>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <WorkcenterProcessesCard
          :canEdit="appStore.canEditCurrentFacility"
          :workcenterId="id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed, watch, ref, toRef } from "vue-demi";
import { useGet } from "feathers-pinia";
import { useStore } from "../store/app.pinia";
import { useWorkcenters } from "../store/workcenters.pinia";
import { useFacilities } from "../store/facilities.pinia";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import InlineEdit from "./InlineEdit.vue";
import InlineForm from "./InlineFieldsForm.vue";
import WorkcenterProcessesCard from "./WorkcenterProcessesCard.vue";
import rules from "../utils/rules";

export default {
  name: "WorkcenterOverview",
  props: {
    workcenterId: {
      type: String,
      required: true,
    },
  },
  components: {
    InlineEdit,
    InlineForm,
    WorkcenterProcessesCard,
  },
  setup(props) {
    const appStore = useStore();
    const workcentersStore = useWorkcenters();
    const facilitiesStore = useFacilities();
    const processTemplatesStore = useProcessTemplates();

    const id = toRef(props, "workcenterId");
    const createdUserId = ref(null);
    const modifiedUserId = ref(null);

    const { item: workcenter, isPending } = useGet({
      model: workcentersStore.Model,
      id: id,
    });

    const hasActiveProcesses = computed(() => {
      return processTemplatesStore
        .byWorkcenterId(workcenter?.value?.id)
        .filter((item) => item.active).length
        ? true
        : false;
    });

    watch(
      () => workcenter?.value,
      () => {
        if (!workcenter?.value) return;
        createdUserId.value = workcenter.value?.created_by_id;
        modifiedUserId.value = workcenter.value?.modified_by_id;
      },
      { immediate: true }
    );

    const uniqueTitle = (val) => {
      return (
        workcentersStore.hasUniqueTitle(
          val,
          workcenter?.value?.facility_id,
          workcenter?.value?.id
        ) || "Title must be unique"
      );
    };

    return {
      id,
      appStore,
      facilitiesStore,
      workcenter,
      isPending,
      workcentersStore,
      rules,
      hasActiveProcesses,
      uniqueTitle,
    };
  },
};
</script>
