<template>
  <v-card>
    <v-card-title>
      Processes
      <v-tooltip v-for="chip in chips" :key="chip.tooltip" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="mr-0 ml-2"
            :color="chip.color"
            text-color="white"
            small
            v-bind="attrs"
            v-on="on"
          >
            {{ chip.count }}
          </v-chip>
        </template>
        <span v-text="chip.tooltip"></span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-checkbox label="Only Active" v-model="onlyActive" class="pr-2" />
      <v-tooltip v-if="canEdit" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="addProcess"
            :disabled="!workcentersStore.isActive(workcenterId)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Process</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>

    <v-expansion-panels v-model="openPanels" multiple accordion>
      <WorkcenterProcessesCardItem
        v-for="(item, i) in items"
        :index="i"
        :ref="getItemRef(item)"
        :key="getItemKey(item)"
        :entity="item"
        :canEdit="canEdit"
      />
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { computed, ref, toRef, nextTick, watch } from "vue-demi";
import { useFind } from "feathers-pinia";
import { sortByStringProperty } from "../utils";
import { useProcessTemplates } from "../store/processTemplates.pinia";
import { useWorkcenters } from "../store/workcenters.pinia";
import WorkcenterProcessesCardItem from "./WorkcenterProcessesCardItem.vue";

export default {
  name: "WorkcenterProcessesCard",
  components: {
    WorkcenterProcessesCardItem,
  },
  props: {
    workcenterId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { root, refs }) {
    const processTemplatesStore = useProcessTemplates();
    const workcentersStore = useWorkcenters();
    const workcenterId = toRef(props, "workcenterId");
    const openPanels = ref([]);
    const sortByTitle = sortByStringProperty("title");
    const onlyActive = ref(true);

    const params = computed(() => {
      return {
        temps: true,
        query: {
          workcenter_id: workcenterId.value,
          $limit: 1000,
        },
      };
    });

    const { items } = useFind({
      model: processTemplatesStore.Model,
      params,
    });

    const processes = computed(() => {
      return (
        items?.value
          // respect active flag
          ?.filter((i) => (onlyActive.value ? i.active : true))
          // sort by title
          ?.sort(sortByTitle) ?? []
      );
    });

    const chips = computed(() => {
      return [
        {
          tooltip: "Active Processes",
          count: items?.value?.filter((i) => !!i.active).length ?? 0,
          color: "success",
        },
        {
          tooltip: "Inactive Processes",
          count: items?.value?.filter((i) => !i.active).length ?? 0,
          color: "red",
        },
      ];
    });

    const getItemKey = (item) => {
      return item.id ?? item.__tempId ?? 999;
    };

    const getItemRef = (item) => {
      return `process-panel-${getItemKey(item)}`;
    };

    const addProcess = (callback) => {
      const item = processTemplatesStore.addProcessTemplate(workcenterId.value);
      // Disable onlyActive if the new item is disabled
      if (!item.active) onlyActive.value = false;
      // Wait for next tick
      nextTick(() => {
        // const newItemIndex = processes.value.findIndex(
        // (cItem) => item.__tempId === getItemKey(cItem)
        // );
        // VueJS is retaining index values, so the newest item is always the last index
        const newItemIndex = processes.value.length - 1;
        // Open the new panel (and close others)
        openPanels.value = [newItemIndex];
        // Scroll to the new panel
        root.$vuetify.goTo(refs[getItemRef(item)][0]);
        if (typeof callback === "function") callback();
      });
    };

    // Reset values when workcenter changes
    watch(
      () => workcenterId.value,
      () => {
        openPanels.value = [];
        onlyActive.value = true;
      }
    );

    // Watch and react to query.addProcess
    watch(
      () => root.$route,
      (route) => {
        if (route.query.addProcess) {
          // Wait for the current list to render
          nextTick(() => {
            // Add process
            addProcess(() => {
              // Replace route with removed query params
              root.$router.replace({
                ...route,
                query: {},
              });
            });
          });
        }
      },
      { immediate: true }
    );

    return {
      items: processes,
      chips,
      processTemplatesStore,
      openPanels,
      onlyActive,
      getItemKey,
      getItemRef,
      addProcess,
      workcentersStore,
    };
  },
};
</script>
